.toggle-menu {
	display: flex;
	flex-direction: column;
	gap: 5px;

	div {
		width: 25px;
		height: 2px;
		border-radius: 20px;
		background-color: black;
		transition: 0.3s ease-in-out;

		&:hover {
			width: 20px;
		}
	}
}
